export const blogs = [
    {
        "title":"Bringing Back Brilliance: The Power of Pressure Washing in Salt Lake City",
        "description":"Blumont Building Services knows that making a lasting impression requires keeping your workplace environment bright and appealing. Operating your business is challenging enough, so we offer full-service building expertise to handle everything, from lawn maintenance to the transformative power of pressure washing in Salt Lake City.",
        "slug":"Bringing-Back-Brilliance-The-Power-of-Pressure-Washing-in-Salt-Lake-City"
    },
    {
        "title":"The Art of Cleanliness: Commercial Cleaning and Janitorial Mastery",
        "description":"At BluMont Building Services, we believe that cleanliness is an art, and we've mastered it to perfection. As Utah's local, private leader in janitorial services, we take pride in providing premium commercial cleaning solutions and janitorial mastery for various facilities and operations.",
        "slug":"The-Art-of-Cleanliness-Commercial-Cleaning-and-Janitorial-Mastery"
    },
    {
        "title":"Floors That Impress: Blumont's Exceptional Care Services",
        "description":"At BluMont Building Services, we understand that the first step to creating a lasting impression lies beneath your feet. The state of your floors speaks volumes about your dedication to maintaining a tidy and businesslike atmosphere.",
        "slug":"Floors-That-Impress-Blumonts-Exceptional-Care-Services"
    },
    {
        "title":"Clarity beyond Glass: Window Washing Magic in Salt Lake City",
        "description":"First impressions matter in the bustling city of Salt Lake City, where the vibrant urban atmosphere meets stunning natural landscapes. One often-overlooked element that contributes significantly to the overall appearance of any building is clean and sparkling windows.",
        "slug":"Clarity-beyond-Glass-Window-Washing-Magic-in-Salt-Lake-City"
    }
]