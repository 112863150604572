import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { themeStyles, colors } from '../utils/theme'
import { blogs } from '../utils/blogs'
import PageTitle from '../components/PageTitle'

const BlogList = ({ data, location }) => {
  return (
    <Layout>
        <PageTitle fontSize={2} title={"Latest Blogs"}/>
      <div css={[themeStyles.contentWidth, { textAlign: 'center', backgroundColor: colors.lightGrey }]}>
        <div css={[themeStyles.textPadding, { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }]}>
        
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {blogs.map((blog, index) => (
              <li key={index} style={{ marginTop: '20px', textAlign: 'left' }}>
                <div>
                    <img src={require(`../assets/${blog.slug}.jpg`)} alt="Thumbnail" style={{ width: 'auto', height: '100px', marginRight: '10px', float: 'left' }} />
                  </div>
                <Link to={"/blog/"+blog.slug}><h2>{blog.title}</h2></Link>
                <p>{blog.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default BlogList
